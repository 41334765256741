import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { redirectIfNeeded } from "../utils/helpers"

import SliceZone from "../components/slice-zone"
import { withPreview } from "gatsby-source-prismic"

export const query = graphql`
  query HomepageQuery($uid: String, $lang: String) {
    prismicHomepage(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        meta_image {
          url
        }
        meta_title
        meta_keywords
        meta_description
        body {
          ... on PrismicHomepageBodyHeroSectionWithSignupForm {
            id
            slice_type
            primary {
              has_waves_cutout
              form_title
              anchor_id
              recruiter_hero_subtitle: subtitle
              recruiter_hero_title: title
              background_image {
                url
              }
            }
          }
          ... on PrismicHomepageBodyHeroSection {
            id
            slice_type
            primary {
              link_text
              hero_subtitle: subtitle
              theme
              hero_title: title
              has_waves_cutout
              button_text
              link {
                url
                type
                target
              }
              background_image {
                url
              }
            }
          }
          ... on PrismicHomepageBodyPartnersCarousel {
            id
            slice_type
            primary {
              title_is_bold
              partners_carousel_title: title
            }
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageBodyTestimonialCarousel {
            id
            slice_type
            primary {
              testimonial_carousel_title: title
            }
            items {
              name
              role
              testimonial_carousel_text {
                html
                raw
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 80) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageBodyInfoWithIcons {
            id
            slice_type
            primary {
              info_with_icons_subtitle: subtitle
              info_with_icons_title: title
            }
            items {
              info_body {
                html
                raw
                text
              }
              link_text
              info_with_icons_item_title: title
              link_url {
                url
                type
                target
              }
              button_icon {
                url
              }
            }
          }
          ... on PrismicHomepageBodyHomepageIntegrations {
            id
            slice_type
            primary {
              integrations_title: title
              integrations_subtitle: subtitle
              button_text
              button_link {
                url
                type
                target
              }
            }
            items {
              logo {
                url
              }
            }
          }
          ... on PrismicHomepageBodyPricingCards {
            id
            slice_type
            slice_label
            primary {
              pricing_cards_title: title
              button_text
              button_url {
                url
                type
                target
              }
            }
            items {
              plan_name
              plan_subtitle
              price_line {
                html
                raw
                text
              }
              plan_body {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyNetwork {
            id
            slice_type
            primary {
              sector1
              sector2
              sector3
              network_title: title
              image {
                url
              }
            }
          }
          ... on PrismicHomepageBodyFaqVideo {
            id
            slice_type
            slice_label
            primary {
              button_text
              faq_video_title: title
              faq_video_subtitle: subtitle
              button_url {
                url
                type
                target
              }
            }
            items {
              tab_title
              video_number
            }
          }
          ... on PrismicHomepageBodyFeatures {
            id
            slice_type
            primary {
              features_title: title
              button_text
              button_link {
                url
                type
                target
              }
              body1 {
                html
                raw
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 80) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageBodyContentBlog {
            id
            slice_type
            slice_label
            primary {
              content_blog {
                html
                raw
                text
              }
              text_color
            }
          }
          ... on PrismicHomepageBodyBubbles {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              subtitle_bubbles
              title {
                html
                raw
                text
              }
            }
            items {
              image {
                url
              }
              text {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyScreenshots {
            id
            slice_type
            slice_label
            items {
              image {
                url
              }
              text {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyTiledContent {
            id
            slice_type
            slice_label
            items {
              cta_button
              cta_url
              image {
                url
              }
              text {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            primary {
              subtitle {
                html
                raw
                text
              }
              anchor_id
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodySingleColumn {
            id
            slice_type
            slice_label
            items {
              text {
                html
                raw
                text
              }
            }
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyPool {
            id
            slice_type
            slice_label
            items {
              image {
                url
              }
              linked
            }
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyPlans {
            id
            slice_type
            slice_label
            items {
              benefits {
                html
                raw
                text
              }
              frequency
              price
              subtitle
              title {
                html
                raw
                text
              }
            }
            primary {
              anchor_id
              subtitle {
                text
                raw
                html
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyContactUs {
            id
            slice_type
            slice_label
            primary {
              anchor_id
            }
          }
          ... on PrismicHomepageBodyCta {
            id
            slice_type
            slice_label
            primary {
              cta_centered {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyPageHead {
            id
            slice_type
            slice_label
            primary {
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyLogoCarousel {
            id
            slice_type
            slice_label
            items {
              logo {
                url
              }
            }
            primary {
              anchor_id
              subtitle_carousel
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyCustomTiled {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            items {
              text_order
              button_url
              button_text
              text {
                raw
              }
              title {
                raw
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicHomepageBodyInfoBox {
            id
            slice_type
            slice_label
            primary {
              description {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyOverviewSection {
            id
            slice_type
            slice_label
            items {
              section_anchor_id
              section_title
            }
            primary {
              anchor_id
              overview_title
            }
          }
          ... on PrismicHomepageBodyFlowChart {
            id
            slice_type
            slice_label
            items {
              flow
            }
            primary {
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyVerticalSpace {
            id
            slice_type
            slice_label
            primary {
              size
            }
          }
          ... on PrismicHomepageBodyLinkBox {
            id
            slice_type
            slice_label
            items {
              link_url {
                url
                link_type
                slug
              }
              link_title
            }
            primary {
              link_box_title
            }
          }
          ... on PrismicHomepageBodyProcess {
            id
            slice_type
            primary {
              process_title: title
            }
            items {
              process_item_title: title
              image {
                url
              }
              process_body {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyQuoteBox {
            id
            slice_type
            slice_label
            primary {
              quote_text {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyDivider {
            id
            slice_type
          }
          ... on PrismicHomepageBodyContinueReading {
            id
            slice_type
            primary {
              continue_reading_title: title
              continue_reading_custom_tag {
                url
                type
                lang
                uid
              }
            }
          }
        }
      }
    }
  }
`

const HomepageTemplate = ({ data, pageContext, uri, location }) => {
  const { lang: locale } = pageContext
  useEffect(() => {
    redirectIfNeeded({ locale, location })
  }, [])

  const doc = data.prismicHomepage.data

  if (!doc) return null
  const { meta_title, meta_description, meta_keywords, meta_image } = doc

  return (
    <Layout
      metadata={{
        title: meta_title,
        description: meta_description,
        keywords: meta_keywords,
        image: meta_image && meta_image.url,
      }}
      mainPage="companies"
      locale={pageContext.lang}
      path={location && location.pathname}
    >
      {doc.body && (
        <SliceZone
          shouldWrapDoc
          allSlices={doc.body}
          locale={locale}
          path={location && location.pathname}
        />
      )}
    </Layout>
  )
}

export default withPreview(HomepageTemplate)
